<template>
    <v-container fluid>
        <v-form v-model="isFormValid">
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col :cols="12">
                                Import Samples
                            </v-col>
                        </v-row>
                        <v-row class="mb-0 pb-0">
                            <v-col class="pa-0 ma-0">
                                <v-btn
                                    color="tertiary"
                                    dark
                                    class="ml-2 mb-2"
                                    @click="downloadTemplate()">
                                    Download Template
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <app-autocomplete
                                    v-model="labId"
                                    label="Lab"
                                    :schema="getSchema('Labs')"
                                    list-url="/api/Labs/List"
                                    url="/api/Labs"
                                    item-text="name"
                                    :rules="labIdRules" />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-file-input
                                    ref="file"
                                    :key="'fileKey' + fileKey"
                                    style="display: none"
                                    @change="importExcelFile" />
                                <v-btn
                                    :disabled="!isFormValid"
                                    @click="$refs.file.$refs.input.click()">
                                    Import from Excel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>

                <v-card-text>
                    <v-progress-circular
                        v-if="loading"
                        :width="4"
                        :size="30"
                        indeterminate
                        color="primary" />
                    <v-row v-if="errors.length > 0">
                        <v-col :cols="12">
                            <v-row>
                                <v-col>
                                    <h2 :style="{ color: 'black' }">
                                        Errors
                                    </h2>
                                </v-col>
                            </v-row>
                            <v-data-table
                                class="edit-data-table"
                                :headers="errorHeaders"
                                :items="errors">
                                <template
                                    v-for="header in errorHeaders"
                                    v-slot:[getSlotName(header.value)]="{ item }">
                                    <template v-if="typeof(item[header.value]) === 'boolean'">
                                        {{ item[header.value] ? 'Yes' : '' }}
                                    </template>
                                    <template v-else>
                                        {{ item[header.value] }}
                                    </template>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </v-container>
</template>

<script>
import axios from "axios"
import store from '../store/store'
import exporter from "@/export/exporter";

export default {
    components: {
        appAutocomplete: () => import('../components/AppAutocomplete')
    },

    data() {
        return {
            loading: false,
            labId: null,
            isFormValid: false,
            fileKey: 0,

            errorHeaders: [
                { text: 'Error Message', value: 'message' }
            ],
            errors: [],
            labIdRules: [
                v => !!v || 'Lab is required'
            ],
            hasReportBeenRun: false
        }
    },
    watch: {
        labId() {
            if (this.labId != null) {
                this.isFormValid = true;
            }
            else {
                this.isFormValid = false;
            }
        },
        loading() {
            if (this.loading === true) {
                this.hasReportBeenRun = true;
            }
        }
    },

    methods: {
        async importExcelFile(excelFile) {
            this.loading = true;
            this.errors = [];
            try {
                let formData = new FormData();
                formData.append("file", excelFile);
                let response = await axios.post('/api/Samples/Import/' + this.labId, formData);
                this.errors = response.data.map(e => ({ message: e }));

                if (!this.errors.length) {
                    store.dispatch('alert/success', 'Records imported successfully')
                }
            } catch(error) {
                this.handleError(error);
            }
            finally {
                this.loading = false;
                this.fileKey++;
            }
        },

        getSlotName(value) {
            return "item." + value;
        },

        downloadTemplate() {
            try {
                return axios
                    .get('/api/Samples/Template', { responseType: 'blob' })
                    .then(exporter.saveBlob)
            }
            catch(error) {
                let message = 'There was an error downloading the file. ' + error;
                store.dispatch('alert/error', message)
            }
        }
    }
}
</script>
<style scoped>
    td, th {
        border: 1px solid black;
        font-size: 14px;
        word-break: break-word;
        line-height: normal;
    }

    .invisible {
        border: none;
        background-color: transparent;
    }

    .leftHeader {
        font-weight: bold;
        background-color: lightgray;
    }

    th {
        background-color: lightgray;
    }
</style>
